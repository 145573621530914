import enDictionary from '../language/en.json';
import idDictionary from '../language/id.json';
import langDictionary from '../language/lang.json';

export interface GlobalDictionary {
  "SN": number;
  "Section": string;
  "EN": string;
  "ID": string;
  "Notes": any | null;
}

export interface Dictionary {
  en: Record<string, string>;
  id: Record<string, string>;
  global: Array<Partial<GlobalDictionary>>;
}

const strings: Dictionary = {
  en: enDictionary,
  id: idDictionary,
  global: langDictionary,
};

export const wordLanguage = (word: any, language?: keyof Dictionary) => {
  const words = language ? (strings[language] as any)[word] : word;
  return words;
};
export const wordLanguageSortById = (id: any, language?: keyof Dictionary) => {
  const words = langDictionary[id][language === 'id' ? 'ID' : 'EN'];
  return words?.replace('\n', '<br />');
};
export const wordLanguageSortByIdFullArray = (id: any) => {
  const words = langDictionary[id];
  return words;
};
