import { useReducer } from "react";

export const scrollToElementId = (id: string) => {
  document.getElementById(id)?.scrollIntoView({
    behavior: 'smooth'
  });
}

export const useMultipleState = <T extends object>(initialState: T) => {
  return useReducer((s: T, ns: Partial<T>) => ({...s, ...ns}), initialState)
}