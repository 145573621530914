import { createContext, Dispatch, useContext } from "react";
import { Dictionary } from "../handler/language";
import session from "../handler/session";

export interface InitialGlobalState {
  language: keyof Dictionary;
  token: string;
  auth: string;
}

export interface InitialGlobalContext {
  state: Partial<InitialGlobalState>;
  dispatch: Dispatch<Partial<InitialGlobalState>>;
}

export const globalStores: Partial<InitialGlobalState> = {
  language: (session.getStorage('language') as keyof Dictionary) ?? 'en',
  token: undefined,
  auth: undefined,
}

export const GlobalContext = createContext<InitialGlobalContext>({
  state: globalStores,
  dispatch: () => {}
})

export const useGlobalContext = () => useContext(GlobalContext)