const getStorage = (data: string) => {
  try {
    const setdata = localStorage.getItem(data);
    return setdata;
  } catch (err) {
    return null;
  }
};

const setStorage = (data: string, value: string) => {
  try {
    const setdata = localStorage.setItem(data, String(value));
    return setdata;
  } catch (err) {
    return null;
  }
};

export default {
  getStorage,
  setStorage,
};
