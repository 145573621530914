import React from 'react';
import './index.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import GlobalProvider from './provider/global-provider';

const root = createRoot(document.getElementById('root')!!);

root.render(
  <HelmetProvider>
    <React.StrictMode>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </React.StrictMode>
  </HelmetProvider>
);
