import React from "react"
import { FC, PropsWithChildren } from "react";
import { useMultipleState } from "../helper";
import { GlobalContext, globalStores, InitialGlobalState } from "./store";

const GlobalProvider: FC<PropsWithChildren> = ({children}) => {
  const [state, dispatch] = useMultipleState<Partial<InitialGlobalState>>(globalStores)

  return (
    <GlobalContext.Provider value={{state, dispatch}}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider